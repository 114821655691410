

/*===========================
  --- Header ----
=========================== */ 

.headerwrap {
	background: url('../img/other/header.png') no-repeat center center;
	background-color: rgba(239,246,251,1);
	max-height: 100%;
	height: 350px;
    position: relative;
  
    -webkit-background-size: 100%;
    -moz-background-size: 100%;
    -o-background-size: 100%;
    background-size: 100%;

    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}

.headerwrap, .mvp{
background-color: rgba(239,246,251,1);
}


/*===========================
  --- For All Sections ----
=========================== */

section {
  margin: 0;
  padding: 3.5rem 0;
}

/*===========================
  --- Services 3 points ----
=========================== */

.service{
	border-radius:calc(0.25rem - 1px);
	background-color:white;
	padding: 1.5rem; 
	border: 1px solid rgba(0, 0, 0, 0.125);
}

.service img{
	height: 40px;
}

.service .cover{
	object-fit:contain;
}

.card-img-top{
height: 12rem;
object-fit:cover;

}

.useme h2, .useme h4{
  font-weight: 700;
}


/*===========================
  --- Simple ----
=========================== */

.service img {
	width: 100%;
}

.voyageuse img {
	width: 100%;
}
.voyageuse h2{
  font-size: 1.9rem;
}

.section-stats{
  padding: 3.5rem 0;
}

.section-stats .data {
    font-size: 1.75rem;
    margin-bottom: 15px;
}
.section-stats hr {
    display: block; 
    border-top: 1px solid #7bb62f;  
    padding: 15px 0 0;
    margin: 0 auto;
    max-width: 5rem;
    border-width: 0.05rem;
    color: #7bb62f;
  }

  .section-stats h3, .section-stats h6{
    font-weight: 700;
  }

  .section-two__home p{
    margin-bottom: 30px;;
  }

  .section-two__home img{
    width: initial;
  }

  ._8f1i{
    width: 190px!important;
  }
  
  .testimony .avatar{
		position:absolute;
	  right: 40%;
	  top: 75%;
	  height: 90px;
	  width:90px;
	  border-radius:50%;
	  overflow:hidden;
	  background-size: cover;
	  z-index:1;
	}

  /* .dropdown-menu.show{ 
    position: absolute!important;
    transform: none!important; 
    top: 100%!important
  }*/















