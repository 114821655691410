
.text-highlight {
  color: #32383e;
}
.label-theme {
  background: #1abc9c;
  font-size: 12px;
}


/* Small devices (tablets, 768px and up) */
/* Medium devices (desktops, 992px and up) */
/* Large devices (large desktops, 1200px and up) */
/* Ex-Large devices (large desktops, 1200px and up) */
@media (min-width: 1400px) {
  .container-fluid {
    width: 1360px;
  }
}

.txt-white{
  color: #ffffff;
}


/* ======================================================================================================
     MENU Secondaire for page tasodo and tasodo account
========================================================================================================= */



#secondNav .nav-item > a { 
	color: #464a4e;
 }

#secondNav .nav-item > a.active,
#secondNav .nav-item > a:hover{ 
border-top: 3px solid #f03508!important;

}

/* ========================================================================== */
.dashboard .level-title {
  font-size: 12px;
  font-weight: normal;
  text-align:right;
   color: #999999; /*  */  
   /*color: #808080;   */
   margin-bottom: 2px;
} 

.dashboard .level-title.active {
 color: #007bff ; 
} 


.dashboard .levelset .col-sm-2{
  position: relative;
  min-height: 1px;
  padding-right: 2px;
  padding-left: 2px;
}

.dashboard .levelset .col-sm-2 .progress{
border-radius: 0px;
}


.dashboard .levelset .col-1:first-child{
  position: relative;
  min-height: 1px;
  padding-right: 2px;
}

.dashboard .levelset .col-1:first-child .progress{
border-radius: calc(0.25rem - 1px) 0 0 calc(0.25rem - 1px);
}


.dashboard .levelset .col-1:last-child{
  position: relative;
  min-height: 1px;
  padding-left: 2px;
}

.dashboard .levelset .col-1:last-child .progress{
border-radius: 0 calc(0.25rem - 1px) calc(0.25rem - 1px) 0;
}

.dashboard  img {
  width: 70px;
  height: 70px;
}

.dashboard  .cover {
  object-fit:cover ;
}




/* Testimonials section of dashboard and profile*/
.testimonials .item {
  margin-bottom: 2rem;
}
.testimonials .item:last-child {
  margin-bottom: 0;
}
.testimonials .item .quote {
  border-left:4px solid #7bb62f;
  padding-left: 1rem;
  margin-bottom: 1rem;
}
.testimonials .item .quote .fa:last-child {
  margin-right: 1rem;
}

.testimonials .item .source .date {
  color: #999999;

}


/* Education section */
.education .item {
  margin-bottom: 30px;
}
.education .item:last-child {
  margin-bottom: 0;
}
.education .item .title {
  margin-top: 0;
  color: #3a4148;
}
.education .item .university {
  font-family: "Lato", arial, sans-serif;
  font-size: 13px;
  color: #999999;
  font-weight: 600;
  padding-left: 25px;
}
.education .item .university .year {
  color: #b0b7bf;
  font-weight: 500;
}
/* Language Section */
.languages .item {
  margin-bottom: 15px;
}
.languages .item .title {
  color: #3a4148;
}
.languages .item .level {
  color: #999999;
}
.languages .item:last-child {
  margin-bottom: 0;
}
.languages .item .fa {
  color: #1abc9c;
}
/* Blog Section */
.blog .item {
  margin-bottom: 30px;
}
.blog .item .title {
  line-height: 1.3;
}
.blog .item .title {
   color: #3a4148;
}

.blog .item:last-child {
  margin-bottom: 0;
}
/* List section */
.list ul li {
  margin-bottom: 10px;
}
.list ul li .fa {
  margin-right: 5px;
}
.list ul li {
  color: #778492;
}



/* price at the end which doesnt have the same structure as it takes the whole width */
.price2{
  margin-bottom: 0px;

}
.price2 .price-inner{
  border-radius:0px;
  border:0px;  
    /*background: #efefef;*/
}


/* oTHEr */
.other{
  margin-bottom: 0px;

}
.other .other-inner{
  border-radius:0px;
  border:0px;  
    background: #efefef;
}

/* ======================================================================================================
    VOYAGES 
========================================================================================================= */

.mytravels .mytravel .status{ 
border-left: 1px solid rgba(0, 0, 0, 0.125);

}

.mytravels .mytravel .status,
.mytravels .mytravel .comments{ 
padding-left: 1rem;
}

.mytravels .mytravel .table { 
  padding-bottom: 0;
  margin-bottom: 0

}

.mytravels .newtravel .billet{ 
font-size:12px;
}

.newtravel .heading{
  font-weight: 600;
}

.mytravels .newtravel hr.hrback {
  border: 5px solid rgba(247, 69, 27, 1);
  border-radius: 4px;
}

/* ======================================================================================================
     EXPED
========================================================================================================= */

.expeds .exped .status,
.expeds .exped .comments{ 
/*border-left: 1px solid rgba(0, 0, 0, 0.125);*/
padding-left: 10px;
}

/*.expeds .exped .criteria{
border-right: 1px solid rgba(0, 0, 0, 0.125);
}*/


/* ======================================================================================================
     MESSAGES
========================================================================================================= */


.messages .message { 
  padding: 20px 20px 5px 20px; 
  background: rgba(231,231,231,0.03);
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.125);
  margin-bottom: 25px;

}

.messages .messageDiff{ 
  background: white;

}


.messages .message img {
  width: 60px;
  height: 60px;
}

.messages .message .cover {
  object-fit:cover ;
}

.messages .message .head {
margin-top: 0;
margin-bottom: 5px;
}

.messages .message p {
margin-bottom: 2px;
}

.messages .message .date {
  font-size: 12px;
  font-weight: normal;
}


/* ======================================================================================================
     ALERTS
========================================================================================================= */


 
.alerts .alert { 
  padding: 20px 20px 5px 20px; 
  background: rgba(231,231,231,0.03);
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.125);

}


.alerts .alert .trajet{ 
 margin-bottom:0px; 
 margin-top:0px;
}

/* ======================================================================================================
     PROFILE
========================================================================================================= */

.profile .photo .cover {
  object-fit:cover ;
}

.profile .photo img {
  width: 100%;
  height: 100%;
}



.profile .verifs .verif {
  border-radius:calc(0.25rem - 1px);
  padding: 1rem 1.5rem;
  overflow:hidden;
  background-color:rgba(0, 0, 0, 0.03);
  margin-top:1rem; 
}


.profile .avis .travel .travel-inner {
  border-radius:calc(0.25rem - 1px);
  padding: 10px 8px;
  border: 1px solid rgba(0, 0, 0, 0.125);
  overflow:hidden;
}





.profile .avis .travel .date {
  margin-top: 0;
  margin-bottom: 2rem;  
}
.profile .avis .travel img {
  width: 60px;
  height: 60px;
}



.profile .avis .travel .cover {
  object-fit:cover ;
}
.profile .avis .travel .detail1 {
  border-right: 1px solid rgba(0, 0, 0, 0.125);
}



.profile .avis .travel .detail3 {
  float:right;
}
.profile .avis .travel .town  { 
font-size: 16px!important;
 }
 
 .profile .avis .travel .weight  { 
font-size: 16px; 
font-weight: 700;
 }





.profile .plans img {
  width: 100%;
}


.profile .plans .cover {
  object-fit:contain;
}




/* ======================================================================================================
     ARGENT
========================================================================================================= */
.moneys label{ 
font-weight: normal;
}

.moneys .money .status,
.moneys .money .comments{ 
border-left: 1px solid rgba(0, 0, 0, 0.125);
padding-left: 10px;
}

.moneys .money .criteria{ 
border-right: 1px solid rgba(0, 0, 0, 0.125);
}


.moneys .money .criteria, 
.moneys .money .comments{ 
font-size:13px;
}


.moneys .CROWFUNDING-button {
  color: #112a3e;
  background-color: #ffc72c; 
  border-color: #ffc72c; 

}

.moneys .CROWFUNDING-button:hover {
  color: #112a3e;
  background-color: #ffc013;
  border-color: #ffc013;
}

.moneys .crowfund img{
  width: 100%;
}


.moneys .crowfund .cover {
  object-fit:contain;
}

.money .money-header .media {
  padding : 5px;
}

.money .money-header .media-body{
  margin-left: 5px;
  padding : 0;
}

.money .money-header p{
  font-size: 12px;
}


/* ======================================================================================================
     MESSAGES
========================================================================================================= */







