.header-navbar .navbar-container .dropdown-language .selected-language {
  font-weight: 500
}


.header-navbar .dropdown-menu,
.horizontal-menu-wrapper .dropdown-menu {
  -webkit-animation-duration : .3s;
  animation-duration         : .3s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode        : both;
  -webkit-animation-name     : slideIn;
  animation-name             : slideIn
}

@-webkit-keyframes slideIn {
  100% {
    -webkit-transform: translateY(0);
    transform        : translateY(0);
    opacity          : 1
  }

  0% {
    -webkit-transform: translateY(1rem);
    transform        : translateY(1rem);
    opacity          : 0
  }
}

@keyframes slideIn {
  100% {
    -webkit-transform: translateY(0);
    transform        : translateY(0);
    opacity          : 1
  }

  0% {
    -webkit-transform: translateY(1rem);
    transform        : translateY(1rem);
    opacity          : 0
  }
}

.header-navbar {
  padding           : 0;
  min-height        : 4.45rem;
  font-family       : Montserrat, Helvetica, Arial, serif;
  -webkit-transition: .3s ease all, background 0s;
  transition        : .3s ease all, background 0s;
  z-index           : 997
}

.header-navbar.fixed-top {
  left: 260px
}

.header-navbar[class*=bg-] .navbar-nav .nav-item>a,
.header-navbar[class*=bg-] .navbar-nav .nav-item>a i,
.header-navbar[class*=bg-] .navbar-nav .nav-item>a span,
.header-navbar[class*=bg-] .navbar-nav .nav-item>a svg {
  color: #FFF !important
}

.header-navbar[class*=bg-] .navbar-nav .nav-item .dropdown-menu i,
.header-navbar[class*=bg-] .navbar-nav .nav-item .dropdown-menu svg {
  color: inherit !important
}

.header-navbar[class*=bg-] .navbar-nav .search-input .input,
.header-navbar[class*=bg-] .navbar-nav .search-input .search-list .auto-suggestion span {
  color: #6E6B7B !important
}

.header-navbar.floating-nav {
  position     : fixed;
  right        : 0;
  margin       : 1.3rem 2rem 0;
  width        : calc(100vw - (100vw - 100%) - calc(2rem * 2) - 260px);
  border-radius: .428rem;
  z-index      : 12
}

.header-navbar.navbar-static-top {
  top       : 0;
  right     : 0;
  left      : 260px;
  width     : calc(100vw - (100vw - 100%) - 260px);
  background: 0 0;
  box-shadow: none !important
}

.header-navbar.navbar-border {
  border-bottom: 1px solid #EBE9F1
}

.header-navbar.navbar-shadow {
  box-shadow: 0 4px 24px 0 rgba(34, 41, 47, .1)
}

.header-navbar.navbar-dark.navbar-border {
  border-bottom: 1px solid #3B4253
}

.header-navbar .navbar-container {
  padding                : .8rem 1rem;
  -webkit-flex-basis     : 100%;
  -ms-flex-preferred-size: 100%;
  flex-basis             : 100%;
  -webkit-transition     : .3s ease all;
  transition             : .3s ease all;
  margin-left            : 0
}

@media (min-width:576px) {
  .form-horizontal .form-group .label-control {
    text-align: right
  }

  .header-navbar .navbar-container {
    padding: .8rem 1.4rem
  }
}

.header-navbar .navbar-container .bookmark-wrapper .bookmark-input {
  z-index: 1
}

.header-navbar .navbar-container .dropdown-language .dropdown-menu .dropdown-item .flag-icon,
.header-navbar .navbar-container .dropdown-language .nav-link .flag-icon {
  margin-right: .4rem
}

.header-navbar .navbar-container ul.navbar-nav li {
  line-height: 1.5
}

.vertical-layout .header-navbar .navbar-container ul.navbar-nav li.dropdown .dropdown-menu {
  top: 48px !important
}

.header-navbar .navbar-container ul.navbar-nav li.dropdown-language .dropdown-menu.dropdown-menu-right {
  right: -2px
}

.header-navbar .navbar-container ul.navbar-nav li.dropdown-cart .badge.badge-up,
.header-navbar .navbar-container ul.navbar-nav li.dropdown-notification .badge.badge-up {
  right: -3px
}

.header-navbar .navbar-container ul.navbar-nav li.dropdown-cart .dropdown-menu.dropdown-menu-right,
.header-navbar .navbar-container ul.navbar-nav li.dropdown-notification .dropdown-menu.dropdown-menu-right {
  right  : -2px;
  padding: 0;
  left   : inherit
}

.header-navbar .navbar-container ul.navbar-nav li.dropdown-cart .dropdown-menu.dropdown-menu-right::before,
.header-navbar .navbar-container ul.navbar-nav li.dropdown-notification .dropdown-menu.dropdown-menu-right::before {
  background  : #7367F0;
  border-color: #7367F0
}

.header-navbar .navbar-container .dropdown-menu-media .media-list .media:hover,
.header-navbar .navbar-container ul.navbar-nav li.dropdown-cart .media img {
  background: #F8F8F8
}

.header-navbar .navbar-container ul.navbar-nav li.dropdown-cart .dropdown-menu-header,
.header-navbar .navbar-container ul.navbar-nav li.dropdown-notification .dropdown-menu-header {
  border-top-left-radius : .358rem;
  border-top-right-radius: .358rem
}

.header-navbar .navbar-container ul.navbar-nav li.dropdown-cart .dropdown-menu-header .dropdown-header,
.header-navbar .navbar-container ul.navbar-nav li.dropdown-notification .dropdown-menu-header .dropdown-header {
  padding: 1.22rem 1.28rem
}

.header-navbar .navbar-container ul.navbar-nav li.dropdown-cart .notification-text,
.header-navbar .navbar-container ul.navbar-nav li.dropdown-notification .notification-text {
  margin-bottom: .5rem;
  font-size    : smaller;
  color        : #B9B9C3
}

.header-navbar .navbar-container ul.navbar-nav li.dropdown-cart .dropdown-menu-footer,
.header-navbar .navbar-container ul.navbar-nav li.dropdown-notification .dropdown-menu-footer {
  padding: 1.28rem
}

.header-navbar .navbar-container ul.navbar-nav li.dropdown-notification .media-body .media-heading {
  color        : #6E6B7B;
  margin-bottom: 0;
  line-height  : 1.2
}

.header-navbar .navbar-container ul.navbar-nav li.dropdown-cart .media {
  position: relative
}

.header-navbar .navbar-container ul.navbar-nav li.dropdown-cart .media:hover .cart-item-remove {
  visibility: visible
}

.header-navbar .navbar-container ul.navbar-nav li.dropdown-cart .media-heading {
  width: 8rem
}

.header-navbar .navbar-container ul.navbar-nav li.dropdown-cart .media-heading h6.cart-item-title {
  display           : -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow          : hidden
}

.header-navbar .navbar-container ul.navbar-nav li.dropdown-cart .media-heading .cart-item-by {
  color             : #B9B9C3;
  display           : -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow          : hidden
}

.header-navbar .navbar-container ul.navbar-nav li.dropdown-cart .media-body {
  display                : -webkit-box;
  display                : -webkit-flex;
  display                : -ms-flexbox;
  display                : flex;
  -webkit-box-align      : center;
  -webkit-align-items    : center;
  -ms-flex-align         : center;
  align-items            : center;
  -webkit-box-pack       : justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack          : justify;
  justify-content        : space-between
}

.header-navbar .navbar-container ul.navbar-nav li.dropdown-cart .cart-item-price {
  margin-bottom: 0;
  width        : 5rem;
  text-align   : right
}

.header-navbar .navbar-container ul.navbar-nav li.dropdown-cart .cart-item-remove {
  position: absolute;
  top     : 10px;
  right   : 17px;
  width   : 14px;
  height  : 14px;
  cursor  : pointer
}

.header-navbar .navbar-container ul.navbar-nav li>a.nav-link {
  color   : #6E6B7B;
  padding : 0 .5rem;
  position: relative
}

.header-navbar .navbar-container ul.navbar-nav li.dropdown-user {
  line-height: 1 !important
}

.badge[class*=badge-] [class*=icon-],
.navbar-dark .navbar-nav li {
  line-height: 1
}

.header-navbar .navbar-container ul.navbar-nav li.dropdown-user .dropdown-menu {
  width     : 12rem;
  margin-top: 10px
}

.header-navbar .navbar-container ul.navbar-nav li.dropdown-user .dropdown-menu i,
.header-navbar .navbar-container ul.navbar-nav li.dropdown-user .dropdown-menu svg {
  height        : 16px;
  width         : 16px;
  font-size     : 16px;
  vertical-align: top
}

.header-navbar .navbar-container ul.navbar-nav li a.dropdown-user-link {
  display            : -webkit-box;
  display            : -webkit-flex;
  display            : -ms-flexbox;
  display            : flex;
  -webkit-box-align  : center;
  -webkit-align-items: center;
  -ms-flex-align     : center;
  align-items        : center
}

.header-navbar .navbar-container ul.navbar-nav li a.dropdown-user-link .user-name {
  display      : inline-block;
  margin-bottom: .435rem;
  margin-left  : .2rem
}

.header-navbar .navbar-container ul.navbar-nav li a.dropdown-user-link .user-status {
  font-size: smaller
}

.header-navbar .navbar-container ul.navbar-nav li a.dropdown-user-link img {
  box-shadow: 0 4px 8px 0 rgba(34, 41, 47, .12), 0 2px 4px 0 rgba(34, 41, 47, .08)
}

.header-navbar .navbar-container ul.navbar-nav li a.menu-toggle i,
.header-navbar .navbar-container ul.navbar-nav li a.menu-toggle svg {
  height   : 1.6rem;
  width    : 1.6rem;
  font-size: 1.6rem
}

.header-navbar .navbar-container ul.navbar-nav li a.dropdown-user-link .user-nav {
  display               : -webkit-box;
  display               : -webkit-flex;
  display               : -ms-flexbox;
  display               : flex;
  -webkit-box-orient    : vertical;
  -webkit-box-direction : normal;
  -webkit-flex-direction: column;
  -ms-flex-direction    : column;
  flex-direction        : column;
  -webkit-box-align     : end;
  -webkit-align-items   : flex-end;
  -ms-flex-align        : end;
  align-items           : flex-end;
  float                 : left;
  margin-right          : .8rem
}

.header-navbar .navbar-container ul.navbar-nav li div.input-group {
  padding: .7rem 1rem
}

.header-navbar .navbar-container ul.navbar-nav li i.ficon,
.header-navbar .navbar-container ul.navbar-nav li svg.ficon {
  height   : 1.5rem;
  width    : 1.5rem;
  font-size: 1.5rem;
  color    : #6E6B7B
}

.header-navbar .navbar-container ul.navbar-nav li i.ficon:hover,
.header-navbar .navbar-container ul.navbar-nav li svg.ficon:hover {
  color: #7367F0
}

.header-navbar .navbar-container ul.navbar-nav li .media-list {
  max-height: 25rem
}

.header-navbar .navbar-container ul.navbar-nav li .scrollable-container {
  position: relative
}

.header-navbar .navbar-container .dropdown-menu-media {
  width: 30rem
}

.header-navbar .navbar-container .dropdown-menu-media .dropdown-menu-header {
  border-bottom: 1px solid #EBE9F1
}

.header-navbar .navbar-container .dropdown-menu-media .media-list .media {
  padding      : .9rem 1.28rem;
  border       : none;
  border-bottom: 1px solid #EBE9F1
}

.header-navbar .navbar-container .dropdown-menu-media .media-list .media .media-meta {
  color: #6E6B7B
}

.header-navbar .navbar-container .dropdown-menu-media .media-list a:last-of-type .media {
  border-bottom: none
}

.header-navbar .navbar-container .dropdown-menu-media .dropdown-menu-footer {
  border-top: 1px solid #EBE9F1
}

.header-navbar-shadow {
  display: none
}

.navbar-floating .navbar-container {
  padding: .8rem 1rem
}

.navbar-floating .header-navbar-shadow {
  display          : block;
  background       : -webkit-linear-gradient(top, rgba(248, 248, 248, .95) 44%, rgba(248, 248, 248, .46) 73%, rgba(255, 255, 255, 0));
  background       : linear-gradient(180deg, rgba(248, 248, 248, .95) 44%, rgba(248, 248, 248, .46) 73%, rgba(255, 255, 255, 0));
  padding-top      : 2.2rem;
  background-repeat: repeat;
  width            : 100%;
  height           : 102px;
  position         : fixed;
  top              : 0;
  left             : 0;
  z-index          : 11
}

.navbar-light,
.navbar-light.navbar-horizontal {
  background: #FFF
}

.navbar-light .navbar-nav .active.nav-link {
  background-color: rgba(34, 41, 47, .03)
}

.navbar-light .navbar-nav .disabled.nav-link {
  color: #B9B9C3
}

.round {
  border-radius        : 1.5rem;
  -webkit-border-radius: 1.5rem;
  -moz-border-radius   : 1.5rem;
  -ms-border-radius    : 1.5rem;
  -o-border-radius     : 1.5rem;
}

@media (max-width:767.98px) {
  .header-navbar .navbar-nav .show {
    position: static
  }

  .header-navbar .navbar-nav .open-navbar-container {
    padding-top: .625rem
  }

  .header-navbar .navbar-container .show .dropdown-menu {
    right     : 0;
    left      : 0 !important;
    float     : none;
    width     : auto !important;
    margin-top: 0;
    overflow  : hidden
  }

  .header-navbar .navbar-container .dropdown-user .dropdown-menu-right {
    right: 0 !important
  }

  .header-navbar .navbar-container ul.navbar-nav li .dropdown-toggle::after {
    margin-right: 0;
    margin-left : -2px
  }

  .navbar-dark .navbar-header .navbar-nav .nav-link {
    color: #FFF
  }

  .navbar-dark .navbar-container .navbar-nav .nav-link,
  .navbar-light .navbar-container .navbar-nav .nav-link,
  .navbar-light .navbar-header .navbar-nav .nav-link {
    color: #B8C2CC
  }
}

@media (max-width:1199.98px) {
  .header-navbar.floating-nav {
    width: calc(100vw - (100vw - 100%) - calc(2rem * 2))
  }

  .header-navbar.navbar-static-top {
    left : 0;
    width: 100%
  }
}

@media (max-width:991.98px) {
  .header-navbar .navbar-nav {
    margin               : 0;
    -webkit-box-orient   : horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-flow    : row wrap;
    -ms-flex-flow        : row wrap;
    flex-flow            : row wrap
  }

  .header-navbar .navbar-nav .dropdown-menu {
    position: absolute
  }

  .header-navbar .navbar-nav .nav-item {
    float: left
  }
}

@media (max-width:767px) {
  .header-navbar .navbar-nav {
    margin               : 0;
    -webkit-box-orient   : horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-flow    : row wrap;
    -ms-flex-flow        : row wrap;
    flex-flow            : row wrap
  }

  .header-navbar .navbar-nav .dropdown-menu {
    position: absolute
  }

  .header-navbar .navbar-nav .nav-item {
    float: left
  }
}

.nav-pills.nav-justified .nav-item,
.nav-tabs.nav-justified .nav-item {
  float: none
}

@media (max-width:575.98px) {
  .header-navbar.floating-nav {
    width       : calc(100vw - (100vw - 100%) - calc(2rem + .4rem)) !important;
    margin-left : 1rem;
    margin-right: 1.2rem
  }

  .header-navbar .navbar-container ul.navbar-nav li .selected-language {
    display: none
  }
}

@media only screen and (min-device-width:26.78em) and (max-device-width:47.64em) and (-webkit-min-device-pixel-ratio:2) and (orientation:landscape) {
  .header-navbar .navbar-container .show .dropdown-menu {
    max-height: 180px
  }
}

@media only screen and (min-device-width:29.57em) and (max-device-width:52.57em) and (-webkit-min-device-pixel-ratio:3) and (orientation:landscape) {
  .header-navbar .navbar-container .show .dropdown-menu {
    max-height: 280px
  }
}

@media (max-width:1201px) {
  .header-navbar.fixed-top {
    left: 0
  }

  .horizontal-layout .header-navbar .navbar-container {
    padding-left : .8rem;
    padding-right: .8rem
  }
}

@media (max-width:1199px) {
  .header-navbar .navbar-container ul.navbar-nav li a.dropdown-user-link .user-name {
    margin-bottom: 0
  }
}

.navbar-floating .navbar-container {
  padding: .8rem 1rem
}

.navbar-floating .header-navbar-shadow {
  display          : block;
  background       : -webkit-linear-gradient(top, rgba(248, 248, 248, .95) 44%, rgba(248, 248, 248, .46) 73%, rgba(255, 255, 255, 0));
  background       : linear-gradient(180deg, rgba(248, 248, 248, .95) 44%, rgba(248, 248, 248, .46) 73%, rgba(255, 255, 255, 0));
  padding-top      : 2.2rem;
  background-repeat: repeat;
  width            : 100%;
  height           : 102px;
  position         : fixed;
  top              : 0;
  left             : 0;
  z-index          : 11
}

.navbar-light,
.navbar-light.navbar-horizontal {
  background: #FFF
}



.header-menu .header-navbar.navbar-fixed {
  position: fixed;
  width   : 100%;
}

.header-menu .header-navbar {
  background : #FFF;
  z-index    : 999 !important;
  line-height: 1;
  min-height : auto;
}

.header-menu .header-navbar.navbar-brand-center .navbar-header {
  position: absolute;
  left    : calc(50% - 56px);
  padding : 0;
  z-index : 1000;
}

.header-menu .navbar.header-navbar .navbar-container {
  padding: .8rem 2rem;
}

.header-menu .header-navbar .navbar-container {
  border-radius: .357rem;
}

.header-menu .content {
  margin-left: 0;
}

.header-content {
  display        : flex;
  flex-wrap      : wrap;
  align-items    : center;
  justify-content: space-between;
  width          : 100%;
}

.logout:not(:disabled):not(.disabled) {
  cursor         : pointer;
  text-decoration: inherit;
}

.d-shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.105) !important;
}

.header-layout.navbar-floating .main-menu-wrapper .navbar-horizontal.floating-nav {
  margin: 1.3rem 2rem 0;
}

.header-layout.navbar-floating .main-menu-wrapper {
  background       : -webkit-linear-gradient(top, rgba(248, 248, 248, .95) 44%, rgba(248, 248, 248, .46) 73%, rgba(255, 255, 255, 0) 100%);
  background       : linear-gradient(to bottom, rgba(248, 248, 248, .95) 44%, rgba(248, 248, 248, .46) 73%, rgba(255, 255, 255, 0) 100%);
  background-repeat: repeat-x;
}

.header-menu .main-menu-wrapper {
  position: fixed;
  top     : 62px;
  z-index : 990;
  width   : 100%;
}

.header-navbar.floating-nav {
  position     : fixed;
  right        : 0;
  margin       : 1.3rem 2rem 0 !important;
  width        : calc(100vw - (100vw - 100%) - calc(2rem * 2) - 260px);
  border-radius: .428rem;
  z-index      : 12;
}

.header-menu .header-navbar.navbar-horizontal.floating-nav {
  width     : calc(100vw - (100vw - 100%) - calc(2rem * 2));
  background: #FFF;
}

.header-menu .main-menu-wrapper .header-navbar {
  min-height: 4.45rem;
}

.header-menu .main-menu-wrapper .header-navbar .navbar-container {
  padding: 0 1rem;
  width  : 100%;
}

.header-menu .header-navbar.navbar-horizontal ul#main-menu-navigation>li>.dropdown-menu a:hover {
  background-color: transparent;
  color           : #6E6B7B;
}

.header-menu .header-navbar.navbar-horizontal .dropdown-menu {
  min-width : 215px;
  border    : none;
  margin-top: 0;
  min-height: 52px;
}

.header-menu .header-navbar.navbar-horizontal ul#main-menu-navigation>li i,
.header-menu .header-navbar.navbar-horizontal ul#main-menu-navigation>li svg {
  margin-right: .5rem;
  height      : 1.2142rem;
  width       : 1.2142rem;
  font-size   : 1.2rem;
}

.header-menu .main-menu-wrapper .header-navbar .navbar-header {
  display: none;
}

.main-menu-content .nav-item .nav-link:focus,
.main-menu-content .nav-item .nav-link:hover {
  color: #7367F0 !important;

}