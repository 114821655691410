.auth-wrapper {
  display : -webkit-box;
  display : -webkit-flex;
  display : -ms-flexbox;
  display :         flex;
  -webkit-flex-basis : 100%;
  -ms-flex-preferred-size : 100%;
          flex-basis : 100%;
  min-height : 100vh;
  min-height : calc(var(--vh, 1vh) * 100);
  width : 100%;
}

.auth-wrapper .auth-inner {
  width : 100%;
  position : relative;
}

.auth-wrapper.auth-main {
  -webkit-box-align : center;
  -webkit-align-items : center;
  -ms-flex-align : center;
          align-items : center;
  -webkit-box-pack : center;
  -webkit-justify-content : center;
  -ms-flex-pack : center;
          justify-content : center;
  overflow : hidden;
}

.auth-wrapper.auth-main .auth-inner:before {
  width : 244px;
  height : 243px;
  content : ' ';
  position : absolute;
  top : -54px;
  left : -46px;
}

@media (max-width: 575.98px) {
  .auth-wrapper.auth-main .auth-inner:before {
    display : none;
  }
}

.auth-wrapper.auth-main .auth-inner:after {
  width : 272px;
  height : 272px;
  content : ' ';
  position : absolute;
  bottom : -55px;
  right : -75px;
  z-index : -1;
}

@media (max-width: 575.98px) {
  .auth-wrapper.auth-main .auth-inner:after {
    display : none;
  }
}

.auth-wrapper.auth-main .auth-inner {
  max-width : 400px;
}

.auth-wrapper .brand-logo {
  display : -webkit-box;
  display : -webkit-flex;
  display : -ms-flexbox;
  display :         flex;
  -webkit-box-pack : center;
  -webkit-justify-content : center;
  -ms-flex-pack : center;
          justify-content : center;
  margin : 1rem 0 2rem 0;
}