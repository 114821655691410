/*===========================
  --- General----
=========================== */

body {

  color: #464a4e!important;
  /*#626262*/
  background-color: #F8F8F8!important;
  font-style: normal;
  text-shadow: none;
  -webkit-font-smoothing: antialiased;
  -webkit-overflow-scrolling: touch;
}

html {
  overflow-x: hidden;
  font-size: 87.5%
}

html,
body {
  width: 100%;
  height: 100%;
}

html .content {
  padding: 0;
  position: relative;
  -webkit-transition: .3s ease all;
  transition: .3s ease all;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  min-height: calc(100% - 3.35rem);
  margin-left: 260px;
}

.container-fluid-pad {
  padding: 0rem 2rem!important;
}

.container-fluid-br {
  background-color: white;
  border: 1px solid rgba(0,0,0,.125);
  border-radius: max(0px, min(8px, ((100vw - 4px) - 100%) * 9999)) / 8px;
  margin-top: 0;
  margin-bottom: 0;
}

.main-box_content{
  margin-top: 15px;
  margin-bottom: 10px;
  padding-bottom: 15px;
}

.clear {
  clear: both;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 700!;
}

.txt-light {
  font-weight: 300;
}

.txt-regular {
  font-weight: 400;
}

.txt-bold {
  font-weight: 700;
}

.txt-uppercase {
  text-transform: uppercase;
}

.txt-underline {
  text-decoration: underline;
}

.txt-overline {
  text-decoration: overline;
}

.txt-delete {
  text-decoration: line-through;
}

.txt-italic {
  font-style: italic;
}

.txt-oblique {
  font-style: oblique;
}

.txt-justify {
  text-align: justify;
  text-justify: inter-word;
}

.txt-white{
  color:#ffffff!important;
}

/* --- align --- */
.align-left {
  text-align: left;
}

.align-right {
  text-align: right;
}

.align-center {
  text-align: center;
}

.align-justify {
  text-align: justify;
  text-justify: inter-word;
}

.bg-gray{
  background-color: #f2f2f2;
}

/* --- float --- */
.float-left {
  float: left;
}

.float-right {
  float: right;
}


/* --- display ---*/
.d-center{
  margin: 0 auto;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section,
hr {
  display: block;
}




/* ---- padding ---- */
.pad-0{
  padding: 0;
}

/* top */
.pad-top0 {
  padding-top: 0;
}

.pad-top5 {
  padding-top: 0.5rem;
}

.pad-top10 {
  padding-top: 1rem;
}

.pad-top15 {
  padding-top: 1.5rem;
}

.pad-top20 {
  padding-top: 2rem;
}

.pad-top25 {
  padding-top: 2.5rem;
}

.pad-top30 {
  padding-top: 3.0rem;
}

.pad-top35 {
  padding-top: 3.5rem;
}

.pad-top40 {
  padding-top: 4.0rem;
}

/* bottom */
.pad-bot0 {
  padding-bottom: 0;
}

.pad-bot5 {
  padding-bottom: 0.5rem;
}

.pad-bot10 {
  padding-bottom: 1rem;
}

.pad-bot15 {
  padding-bottom: 1.5rem;
}

.pad-bot20 {
  padding-bottom: 2rem;
}

.pad-bot25 {
  padding-bottom: 2.5rem;
}

.pad-bot30 {
  padding-bottom: 3.0rem;
}

.pad-bot35 {
  padding-bottom: 3.5rem;
}

.pad-bot40 {
  padding-bottom: 4.0rem;
}

.pad-bot50 {
  padding-bottom: 5rem;
}

/* left */
.pad-left0 {
  padding-left: 0;
}

.pad-left5 {
  padding-left: 0.5rem;
}

.pad-left10 {
  padding-left: 1rem;
}

.pad-left15 {
  padding-left: 1.5rem;
}

.pad-left20 {
  padding-left: 2rem;
}

.pad-left25 {
  padding-left: 2.5rem;
}

.pad-left30 {
  padding-left: 3.0rem;
}

.pad-left35 {
  padding-left: 3.5rem;
}

.pad-left40 {
  padding-left: 4.0rem;
}

/* right */
.pad-right0 {
  padding-right: 0;
}

.pad-right5 {
  padding-right: 0.5rem;
}

.pad-right10 {
  padding-right: 1rem;
}

.pad-right15 {
  padding-right: 1.5rem;
}

.pad-right20 {
  padding-right: 2rem;
}

.pad-right25 {
  padding-right: 2.5rem;
}

.pad-right30 {
  padding-right: 3.0rem;
}

.pad-right35 {
  padding-right: 3.5rem;
}

.pad-right40 {
  padding-right: 4.0rem;
}


/* ---- margin --- */
.mar-0 {
  margin : 0;
}
/* left */
.mar-left0 {
  margin-left: 0;
}

.mar-left5 {
  margin-left: 0.5rem;
}

.mar-left10 {
  margin-left: 1rem;
}

.mar-left15 {
  margin-left: 1.5rem;
}

.mar-left20 {
  margin-left: 2rem;
}

.mar-left25 {
  margin-left: 2.5rem;
}

.mar-left30 {
  margin-left: 3.0rem;
}

.mar-left35 {
  margin-left: 3.5rem;
}

.mar-left40 {
  margin-left: 4.0rem;
}

/* right */
.mar-right0 {
  margin-right: 0;
}

.mar-right5 {
  margin-right: 0.5rem;
}

.mar-right10 {
  margin-right: 1rem;
}

.mar-right15 {
  margin-right: 1.5rem;
}

.mar-right20 {
  margin-right: 2rem;
}

.mar-right25 {
  margin-right: 2.5rem;
}

.mar-right30 {
  margin-right: 3.0rem;
}

.mar-right35 {
  margin-right: 3.5rem;
}

.mar-right40 {
  margin-right: 4.0rem;
}

/* top */
.mar-top0 {
  margin-top: 0;
}

.mar-top5 {
  margin-top: 0.5rem;
}

.mar-top10 {
  margin-top: 1rem;
}

.mar-top15 {
  margin-top: 1.5rem;
}

.mar-top20 {
  margin-top: 2rem;
}

.mar-top25 {
  margin-top: 2.5rem;
}

.mar-top30 {
  margin-top: 3.0rem;
}

.mar-top35 {
  margin-top: 3.5rem;
}

.mar-top40 {
  margin-top: 4.0rem;
}

.mar-top-20 {
  margin-top: -2rem;
}

.mar-top-30 {
  margin-top: -3.0rem;
}


/* top */
.mar-auto {
  margin: auto;
}


/* bottom */
.mar-bot0 {
  margin-bottom: 0;
}

.mar-bot5 {
  margin-bottom: 0.5rem;
}

.mar-bot10 {
  margin-bottom: 0.5rem;
}

.mar-bot15 {
  margin-bottom: 1.5rem;
}

.mar-bot20 {
  margin-bottom: 2rem;
}

.mar-bot25 {
  margin-bottom: 2.5rem;
}

.mar-bot30 {
  margin-bottom: 3.0rem;
}

.mar-bot35 {
  margin-bottom: 3.5rem;
}

.mar-bot40 {
  margin-bottom: 4.0rem;
}

.mar-bot-20 {
  margin-bottom: -2rem;
}

.mar-bot-30 {
  margin-bottom: -3.0rem;
}

.mar-bot0-1 {
  margin-bottom: 0.1rem;
  ;
}

/*===========================
  Nice Checkbox and radio
=========================== */

@keyframes hover-color {
  from {
    border-color: #c0c0c0;
  }

  to {
    border-color: #007bff;
  }
}

.magic-radio,
.magic-checkbox {
  position: absolute;
  display: none;
}

.magic-radio[disabled],
.magic-checkbox[disabled] {
  cursor: not-allowed;
}

.magic-radio+label,
.magic-checkbox+label {
  position: relative;
  display: block;
  padding-left: 30px;
  cursor: pointer;
  /* vertical-align: middle; */
}

.magic-radio+label:hover:before,
.magic-checkbox+label:hover:before {
  animation-duration: 0.4s;
  animation-fill-mode: both;
  animation-name: hover-color;
}

.magic-radio+label:before,
.magic-checkbox+label:before {
  position: absolute;
  top: 0;
  left: 0;
  display: inline-block;
  width: 20px;
  height: 20px;
  content: '';
  border: 1px solid #c0c0c0;
}

.magic-radio+label:after,
.magic-checkbox+label:after {
  position: absolute;
  display: none;
  content: '';
}

.magic-radio[disabled]+label,
.magic-checkbox[disabled]+label {
  cursor: not-allowed;
}

.magic-radio[disabled]+label:hover,
.magic-radio[disabled]+label:before,
.magic-radio[disabled]+label:after,
.magic-checkbox[disabled]+label:hover,
.magic-checkbox[disabled]+label:before,
.magic-checkbox[disabled]+label:after {
  cursor: not-allowed;
}

.magic-radio[disabled]+label:hover:before,
.magic-checkbox[disabled]+label:hover:before {
  border: 1px solid #e4e4e4;
  animation-name: none;
}

.magic-radio[disabled]+label:before,
.magic-checkbox[disabled]+label:before {
  border-color: #e4e4e4;
}

.magic-radio:checked+label:before,
.magic-checkbox:checked+label:before {
  animation-name: none;
}

.magic-radio:checked+label:after,
.magic-checkbox:checked+label:after {
  display: block;
}

.magic-radio+label:before {
  border-radius: 50%;
}

.magic-radio+label:after {
  top: 6px;
  left: 6px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: #007bff;
}

.magic-radio:checked+label:before {
  border: 1px solid #007bff;
}

.magic-radio:checked[disabled]+label:before {
  border: 1px solid #fdc8bc;
}

.magic-radio:checked[disabled]+label:after {
  background: #fdc8bc;
}

.magic-checkbox+label:before {
  border-radius: 3px;
}

.magic-checkbox+label:after {
  top: 2px;
  left: 7px;
  box-sizing: border-box;
  width: 6px;
  height: 12px;
  transform: rotate(45deg);
  border-width: 2px;
  border-style: solid;
  border-color: #fff;
  border-top: 0;
  border-left: 0;
}

.magic-checkbox:checked+label:before {
  border: #007bff;
  background: #007bff;
}

.magic-checkbox:checked[disabled]+label:before {
  border: #fdc8bc;
  background: #fdc8bc;
}




/*our button: orange (theme2) and yellow*/


.btn-theme2 {
  color: #fff!important;
  background-color: #f7451b!important;
  border-color: #f7451b!important;
}

.btn-theme2:hover {
  color: #fff;
  background-color: #f03508;
  border-color: #f7451a;
}

.btn-theme2:focus,
.btn-theme2.focus {
  box-shadow: 0 0 0 0.2rem rgba(247, 124, 26, 0.5);
}

.btn-theme2.disabled,
.btn-theme2:disabled {
  background-color: #f7451b;
  border-color: #f7451b;
}

.btn-theme2:not([disabled]):not(.disabled):active,
.btn-theme2:not([disabled]):not(.disabled).active,
.show>.btn-theme2.dropdown-toggle {
  color: #fff;
  background-color: #f7451a;
  border-color: #f7451a;
  box-shadow: 0 0 0 0.2rem rgba(247, 124, 26, 0.5);
}


.btn-yellow {
  color: #112a3e;
  background-color: #ffc72c!important;
  border-color: #ffc72c!important;
}

.btn-yellow:hover {
  color: #112a3e;
  background-color: #f8b600;
  border-color: #f8b600;
}

.btn-yellow:focus,
.btn-yellow.focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 234, 44, 0.5);
}

.btn-yellow.disabled,
.btn-yellow:disabled {
  background-color: #ffc72c;
  border-color: #ffc72c;
}

.btn-yellow:not([disabled]):not(.disabled):active,
.btn-yellow:not([disabled]):not(.disabled).active,
.show>.btn-yellow.dropdown-toggle {
  color: #112a3e;
  background-color: #f8b600;
  border-color: #f8b600;
  box-shadow: 0 0 0 0.2rem rgba(255, 234, 44, 0.5);
}


.btn-facebook {
  color: #fff!important;
  background-color: #3b5998!important;
  border-color: #3b5998!important;
}

.btn-facebook:hover {
  color: #fff;
  background-color: #2d4373;
  border-color: #2d4373;
}

.btn-facebook:focus,
.btn-facebook.focus {
  box-shadow: 0 0 0 0.2rem rgba(59, 89, 152, 0.5);
}

.btn-facebook.disabled,
.btn-facebook:disabled {
  background-color: #3b5998;
  border-color: #3b5998;
}

.btn-facebook:not([disabled]):not(.disabled):active,
.btn-facebook:not([disabled]):not(.disabled).active,
.show>.btn-facebook.dropdown-toggle {
  color: #fff;
  background-color: #2d4373;
  border-color: #2d4373;
  box-shadow: 0 0 0 0.2rem rgba(59, 89, 152, 0.5);
}


.btn-google {
  color: #ffffff!important;
  background-color: #db4a39!important;
  border-color: #db4a39!important;
}

.btn-google:hover {
  color: #ffffff;
  background-color: #b43f30!important;
  border-color: #b43f30!important;
}

.btn-google:focus,
.btn-google.focus {
  box-shadow: 0 0 0 0.2rem rgba(183, 63, 48, 0.5);
}

.btn-google.disabled,
.btn-google:disabled {
  background-color: #db4a39;
  border-color: #db4a39;
}

.btn-google:not([disabled]):not(.disabled):active,
.btn-google:not([disabled]):not(.disabled).active,
.show>.btn-google.dropdown-toggle {
  color: #112a3e;
  background-color: #b43f30;
  border-color: #b43f30;
  box-shadow: 0 0 0 0.2rem rgba(183, 63, 48, 0.5);
}


.btn-linkedin {
  color: #fff;
  background-color: #007bb6;
  border-color: #007bb6;
}

.btn-linkedin:hover {
  color: #fff;
  background-color: #2795e9;
  border-color: #2795e9;
}

.btn-linkedin:focus,
.btn-linkedin.focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 182, 0.5);
}

.btn-linkedin.disabled,
.btn-linkedin:disabled {
  background-color: #007bb6;
  border-color: #007bb6;
}

.btn-linkedin:not([disabled]):not(.disabled):active,
.btn-linkedin:not([disabled]):not(.disabled).active,
.show>.btn-linkedin.dropdown-toggle {
  color: #fff;
  background-color: #2795e9;
  border-color: #2795e9;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 182, 0.5);
}


.btn-twitter {
  color: #fff!important;
  background-color: #00ACEE!important;
  border-color: #00ACEE!important;
}

.btn-twitter:hover {
  color: #fff;
  background-color: #3380BB;
  border-color: #3380BB;
}

.btn-twitter:focus,
.btn-twitter.focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 182, 0.5);
}

.btn-twitter.disabled,
.btn-twitter:disabled {
  background-color: #007bb6;
  border-color: #007bb6;
}

.btn-twitter:not([disabled]):not(.disabled):active,
.btn-twitter:not([disabled]):not(.disabled).active,
.show>.btn-twitter.dropdown-toggle {
  color: #fff;
  background-color: #2795e9;
  border-color: #2795e9;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 182, 0.5);
}

.btn-instagram {
  color: #fff!important;
  background: #dd2a7b!important;
  border-color: #dd2a7b!important;
}

.btn-instagram:hover {
  color: #fff;
  background: #b32c69!important;
  border-color:#b32c69!important;
}

.btn-instagram:focus,
.btn-instagram.focus {
  box-shadow: 0 0 0 0.2rem rgba(129, 52, 175, 100,.5);
}

.btn-instagram.disabled,
.btn-instagram:disabled {
  background-color: #8134af; 
  border-color: #8134af; 
}

.btn-instagram:not([disabled]):not(.disabled):active,
.btn-instagram:not([disabled]):not(.disabled).active,
.show>.btn-instagram.dropdown-toggle {
  color: #fff;
  background-color: #8134af; 
  border-color: #8134af; 
  box-shadow: 0 0 0 0.2rem rgba(52, 175, 100,.5);
}


/* COLOR */

.color-orange {
  color: #f7451b;
}

.color-green {
  color: #7bb62f;
}

.color-blue {
  color: #007bff!important;
}

.color-yellow {
  color: #fbbc05;
}

.color-white {
  color: white;
}

.color-text1 {
  color: #464a4e;
}

.color-text2 {
  color: #999999;
}

.color-text3 {
  color: #808080;
}

.color-facebook {
  color: #3b5998;
}

.color-linkedin {
  color: #007bb6;
}


.color-black {
  color: #2f2f2f;
}

.color-red {
  color: red;
}

.bg-color-a {
  background-color: #f7451b;
}

.bg-color-green {
  background-color: #7bb62f!important;
}

.bg-color-blue {
  background-color: #007bff;
}


.bg-color-white {
  background-color: #fff;
}

.bg-color-black {
  background-color: #2f2f2f;
}

.bg-color-global {
  background-color: rgba(0, 0, 0, 0.03);
}

.bg-color-warning {
  background-color: rgba(255, 193, 7, 0.1);
}


a:visited {
  text-decoration: none;
}


a.link:hover {
  text-decoration: none;
  opacity: 0.75;
  cursor: pointer;
}


a.color-white:hover {
  color: white;
}

a.color-blue:hover {
  color: #007bff;
}

a.color-red:hover {
  color: red;
}

a.color-text1:hover {
  color: #464a4e;
}

a.color-text2:hover {

  color: #999999;

}

a.color-text3:hover {
  color: #808080;

}

.btn.btn-link:hover {
  text-decoration: none;
  opacity: 0.75;
  color: #007bff;
}

.btn-group>.dropdown-toggle:not(:first-child){
  background: url('../img/other/chevron-down-solid.svg') no-repeat 1% 20%;
}

.btn-outline-primary:hover{
  color:#ffffff!important;
}

.menu-mobile-dashboard {
  display : none!important;
  width: 100%;
}

.dropdown-menu{
  color: #6E6B7B!important;
}

.dropdown-item{
  padding: .65rem 1.28rem!important;
}

.dropdown-item:focus, .dropdown-item:hover {
  color: #7367F0!important;
  text-decoration: none;
  background-color: rgba(115,103,240,.12)!important;
}