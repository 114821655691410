
.modal-body{
	background-color:rgba(0, 0, 0, 0.03);
}

.modal-title{

}

 .modal-body label{
	 
	  font-weight:300;
  }


 .submit-button {
     display: none!important;
 }