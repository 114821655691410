/* Responsive settings */

@media all and (max-width: 575.98px){
    .footer-content{
        display: flex;
        flex-wrap: wrap;
        text-align: center;
    }

    .social-addons .list-social{
        display: block;
    }    
    
    .social-addons .list-social a:last-child{
        margin-right: 0;
    }
}

@media all and (min-width: 576px) and (max-width: 767.98px){

    .d-sm-none {
        display: none!important;
    }
    .mynavbar .btn-group{
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display:flex;
    }

    .mynavbar button.btn.btn-light{
        display: none!important;
    }

    .menu-mobile-dashboard {
        display : inline-block!important;
        width: 100%;
    }
    .menu-mobile-dashboard-content{
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        -webkit-box-direction: normal;
        -moz-box-direction: normal;
        -webkit-box-orient: vertical;
        -moz-box-orient: vertical;
        -webkit-flex-direction: column;
        -ms-flex-direction: column;
        flex-direction : column;
        -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
        flex-wrap : wrap;
        -webkit-box-pack: center;
        -moz-box-pack: center;
        -webkit-justify-content: center;
        -ms-flex-pack: center;
        justify-content: center;
    }

    .menu-mobile-dashboard-content a{
        font-size: 16px;
        font-weight: 700;
        text-align: center;
        line-height: 34px;
    }

    .footer-content{
        display: flex;
        flex-wrap: wrap;
        text-align: center;
    }

    .social-addons .list-social{
        display: block;
    }    
    
    .social-addons .list-social{
        display: block;
    }

    .social-addons .list-social a:last-child{
        margin-right: 0;
    }
    
}

@media all and (min-width: 768px) and (max-width: 991.98px){

}

@media all and (min-width: 992px) and (max-width: 1199.98px){

}


@media all and (max-width : 1199px){
    .navbar-collapse{
        display: block;
        position: absolute !important;
        right: 0;
        top: 100%;
        background-color: white;
        padding: 0 15px;
        width: 70%;
        z-index: 99;
    }

    .navbar-collapse.show {
        right: 0;
    }

    .header-content.row {
        margin: 0px!important;
    }

    .header-content .navbar-brand{
        margin-left: 15px;
    }

  .navbar-nav{
        margin:1rem 0rem;
        padding-left: 0;
    }
  .nav-item {
        margin-bottom: 1rem;
        padding-left: 0rem !important;
    }
    .lien-menu   {
      border-bottom: 1px solid rgba(0, 0, 0, 0.125);
    }

    .card-header_content .card-header_title{
        width: 100%!important;
    }
    .card-header_content .card-title{
        font-size: 1.4rem!important;
    }
    .card-header_content .card-header_cta{
        width: 50%!important;
        margin-top: 15px;
    }
    .trips-features .features_button a{
        width: 46%!important;
        margin-top: 5px;
        margin-bottom: 15px;
    }

    .trips-features .features_button2 a{
        width: 46%!important;
        margin-top: 5px;
        margin-bottom: 15px;
    }

    .mydashboard .nav, .mytrips .nav, .myexpeditions .nav, .myalerts .nav,
    .mymoney .nav, .myprofile .nav, .mymessage .nav {
        display :none!important;
    }

    .mynavbar .btn-group{
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display:flex;
    }

    .mynavbar button.btn.btn-light{
        display: none!important;
    }

    .menu-mobile-dashboard {
        display : inline-block!important;
        width: 100%;
    }
    .menu-mobile-dashboard-content{
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        -webkit-box-direction: normal;
        -moz-box-direction: normal;
        -webkit-box-orient: vertical;
        -moz-box-orient: vertical;
        -webkit-flex-direction: column;
        -ms-flex-direction: column;
        flex-direction : column;
        -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
        flex-wrap : wrap;
        -webkit-box-pack: center;
        -moz-box-pack: center;
        -webkit-justify-content: center;
        -ms-flex-pack: center;
        justify-content: center;
    }

    .menu-mobile-dashboard-content a{
        font-size: 16px;
        font-weight: 700;
        text-align: center;
        line-height: 34px;
    }
    .menu-mobile-dashboard-content a.items:hover{
        background-color: rgba(0, 123, 255, 1)!important;
        color: #ffffff;
        text-decoration: none;
    }

    .travel .detail1 .media{
        padding: 0;
    }

    .travel .detail1 .media-heading{
        display:none;
    }

    .travel .detail2 .deliver{
        display: none;
    }

    .travel .detail2 {
        padding-right: 0;
    }

    .travel .detail2 .travel_plan {
        font-size: 12px;
    }

    .travel .detail2 .media-heading{
       font-size: 12px;
    }

    .travel .detail1 .photo {
        margin-bottom: 5px;
    }

    .travel .detail1 .photo i{
        position:absolute;
        bottom: 0;
        right:0;
    }

    .travel .detail1 .media-left .media-heading {
        display: block;
        font-size:12px;
        word-break: break-all;
    }

    .travel .detail3 .media-heading, .travel .detail3 .weight {
        font-size: 18px;
    }

    .travel .detail3 .value_amount{
        font-size: 18px;
        color: #007bff;
    }

}

