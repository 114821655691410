.titres {
  border-radius:calc(0.25rem - 1px);
  padding: 10px 0px 10px 20px;
  overflow:hidden;
}

/* ======= Sections commun =============================================== */

.section .section-inner {
  border-radius:calc(0.25rem - 1px);
  background: #fff;
  padding: 20px;
  border: 1px solid rgba(0, 0, 0, 0.125);
  overflow:hidden;
}

.section .heading {
  margin-top: 0;
  margin-bottom: 20px;
  font-weight: 700;
}


/* ======= Annex A props=============================================== */
.block {
  border-radius:calc(0.25rem - 1px);
  padding: 20px;
  border: 1px solid rgba(0, 0, 0, 0.125);
  overflow:hidden;
}

/* ======= Annex Cofondateur-Carrières=============================================== */

.participants .participants-content{
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap; 
  -ms-flex-wrap: wrap; 
  flex-wrap: wrap; 
  -webkit-box-pack: start;
  -moz-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -moz-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
}

.participants-content .media{
  margin: 5px 15px;
} 

.media .cover {
  object-fit:cover ;
}

.media .media-body {
  padding-left: 15px ;
}



/* ======= Sections niveau d'expé =============================================== */

table {
    border-collapse: separate;
    empty-cells: hide;
    table-layout: auto;
    overflow:hidden;
}

table tr th, table tr td {
    border-right: 8px solid white;
    vertical-align: middle;
    width:15%;
    text-align: center;
}
table tr th:last-child, table tr td:last-child {
    border-right: 0px solid #ddd;
}
table tr th:first-child, table tr td:first-child {
    width:25%;
    text-align: left;
    font-weight: bold;
   color: #999999;  
}

table tr th:nth-child(1) {
    background-color: rgba(123,182,47,0);
    
}
table tr th:nth-child(2) {
    background-color: rgba(123,182,47,0.2);    
}
table tr th:nth-child(3) {
    background-color: rgba(123,182,47,0.4);    
}
table tr th:nth-child(4) {
    background-color: rgba(123,182,47,0.6);
    
}
table tr th:nth-child(5) {
    background-color: rgba(123,182,47,0.8);    
}
table tr th:nth-child(6) {
    background-color: rgba(123,182,47,1); 
    color: white;   
}

.pricing-table {
  position: relative;
  display: block;
  margin: 0 5px;
  float: left;
  width: 300px;
  background: #ffffff;
  font-family: Arial, Helvetica, sans-serif;
  text-align: center;
}
.basic {
  border-top: 3px solid #56ca7c;
  border-bottom: 3px solid #56ca7c;
}
.standard {
  border-top: 3px solid #5f5fa7;
  border-bottom: 3px solid #5f5fa7;
}
.premium {
  border-top: 3px solid #0e83cd;
  border-bottom: 3px solid #0e83cd;
}
.pricing-table span {
  display: block;
}
.table-head {
  padding: 25px 0;
  font-size: 28px;
  font-weight: bold;
}
.basic .table-head {
  color: #56ca7c;
}
.standard .table-head {
  color: #5f5fa7;
}
.premium .table-head {
  color: #0e83cd;
}
.price {
  padding: 8px 0;
  font-size: 32px;
  font-weight: bold;
  color: #ffffff;
}
.basic .price {
  background: #56ca7c;
}
.standard .price {
  background: #5f5fa7;
}
.premium .price {
  background: #0e83cd;
}
.table-row {
  padding: 10px 0;
  font-size: 14px;
  font-weight: 400;
  color: #888;
}
.table-row:nth-child(2n) {
  background: #f7f7f7;
}
.purchase {
  padding: 35px 0;
}
a.buy {
  position: relative;
  display: inline-block;
  z-index: 5;
  padding: 7px 15px;
  border-radius: 4px;
  text-decoration: none;
  font-size: 16px;
  font-weight: 500;
  -webkit-transition: color .2s ease, background .5s ease;
  -o-transition: color .2s ease, background .5s ease;
  transition: color .2s ease, background .5s ease;
  overflow: hidden;
}
.basic a.buy {
  border: 2px solid #56ca7c;
  color: #56ca7c;
}
.standard a.buy {
  border: 2px solid #5f5fa7;
  color: #5f5fa7;
}
.premium a.buy {
  border: 2px solid #0e83cd;
  color: #0e83cd;
}
a.buy:hover {
  color: #ffffff;
}
.basic a.buy:hover {
  background: #56ca7c;
}
.standard a.buy:hover {
  background: #5f5fa7;
}
.premium a.buy:hover {
  background: #0e83cd;
}
a.buy:after {
  position: absolute;
  top: 0px;
  left: 0px;
  display: block;
  z-index: -1;
  width: 100%;
  height: 0;
  content: '';
  -webkit-transition: all .2s ease;
  -o-transition: all .2s ease;
  transition: all .2s ease;
}
.basic a.buy:after {
  background: #56ca7c;
}
.standard a.buy:after {
  background: #5f5fa7;
}
.premium a.buy:after {
  background: #0e83cd;
}
a.buy:hover:after {
  height: 100%;
}




/* ======= Sections comment ça marche =============================================== */
.how .howbox { 
  padding: 10px; 
  border-radius: calc(0.25rem - 1px);
  border: 1px solid rgba(0, 0, 0, 0.125);
  margin-bottom: 25px;
  -moz-box-shadow:  0 1px 1px rgba(0, 0, 0, 0.1); 
  -webkit-box-shadow:  0 1px 1px rgba(0, 0, 0, 0.1); 
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
}

.how .howbox-head { 
  padding: 10px; 
  border-radius: calc(0.25rem - 1px);
  margin-bottom: 25px;
}

.how .exp .howbox-head { 
  background-color: #7bb62f; 
}

.how .voy .howbox-head{ 
    background-color: #007bff; 

}


.how hr { 
   width: 30%;
   border-width: 3px;
   margin-top:0px; 
   margin-left:0px; 
}


.how .exp hr{
    border-color: #7bb62f;
   color: #7bb62f;  
}


.how .voy hr{ 
  border-color: #007bff;
color: #007bff ;

}


.how .expli { 
font-size: 12px;
}

/* ======= Sections confiance =============================================== */

.confiance img { 
width: 100%;

}
.confiance .cover {
  object-fit:contain;
}


/* ======= Sections LES avis =============================================== */

.avis img { 
width: 100%;

}
.avis .cover {
  object-fit:contain;
}

.avis-note{
padding: 2% 5%;
background-color: rgba(0,0,0,0.03);
border-radius: 4px;
}

.avis-note label{
font-size: 25px;
color: #007bff;
}


.aside.section{
  margin-top: 0;
}

.btn-selector{
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
} 

.progress{
  background: rgba(123, 182, 47, .28)!important;
}

.cr-auto{
  cursor: auto!important;
}