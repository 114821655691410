.card{
    border: none!important;
    box-shadow: 0 4px 24px 0 rgb(34 41 47 / 10%)!important;
    margin-bottom: 2rem;
}

.card-vh{
    display: block;
    max-height: calc(100%-100px);
}

.card-title{
    font-weight: 400;
    font-size: 1.285rem;
    color: #5E5873;
}