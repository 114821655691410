.app-layout .content {
    margin-left: 0;
}

html .content.app-content .content-overlay {
    position: fixed;
    opacity: 0;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(34,41,47,.5);
    cursor: pointer;
    -webkit-transition: all .7s,z-index 0s;
    transition: all .7s,z-index 0s;
    z-index: -1;
}

.app-layout .app-content {
    padding: calc(2rem + 4.45rem* 2 + 1.3rem) 2rem 0;
}