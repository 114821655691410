/* ======= Sections======================================================================================= */


.crowdfunding .section {
  margin-bottom: 2rem;
  padding: 0; 
}

.section .section-inner {
  border-radius:calc(0.25rem - 1px);
  padding: 20px;
  border: 1px solid rgba(0, 0, 0, 0.125);
  overflow:hidden;
}

.section .title { 
  margin-bottom: 5px;
  
}
.section .sub-title {
  margin-top: 0;
  margin-bottom: 20px;
  font-weight: normal;
  font-size:16px; 
  
}

.share{
  border-radius: calc(0.25rem - 1px);
  padding: 5px 10px ;
  background: rgba(0, 0, 0, 0.03);
   border: 1px solid rgba(0, 0, 0, 0.125);
   margin-bottom: 1.5rem;
 }

.share a{
   margin-right: 5px;
}

.describe h6, .describe h5{
  font-weight: 700;
}

.describe img{
  margin-bottom: 10px;
  margin-top: 5px;
}

hr{
  margin-top:5px; 
}

.proposition{
  border-radius: calc(0.25rem - 1px);
  padding:5px;
  background-color: rgba(239,239,239,0.6); 

}


/*.media {
  padding: 10px;
  background: rgba(51, 122, 183, .1);
  border-radius: calc(0.25rem - 1px);  
}*/

.media img {
  width: 60px;
  height: 60px;
}

.media-left {
  padding-top: 0px;
}

.media-object{
  margin: 0px;

}

 .media .cover {
  object-fit:cover ;
}


.jeparticipe{
  padding-top:20px ;
}


/* ======= Sections======================================================================================= */



.paybox {
  border-radius:calc(0.25rem - 1px);
  border: 1px solid rgba(0, 0, 0, 0.125); 
  overflow:hidden;
}


.payhead { 
  padding: 10px 20px; 
  border-bottom: 1px solid rgba(0, 0, 0, 0.125); 
}


.payfoot { 
  padding: 20px;
  background: rgba(231,231,231,0.4);
}

.payfoot p{ 
  font-size: 12px;
   margin-top: 10px;
  margin-bottom: 0px;
}

.panel .col1{
   padding-right: 3px;
   padding-left: 0px;

}
.panel .col2{
    padding-left: 2px;
  padding-right: 2px;

  
}
.panel .col3{
  padding-left: 4px;
  
}


/* Small devices (tablets, 768px and up) */
/* Medium devices (desktops, 992px and up) */
/* Large devices (large desktops, 1200px and up) */
/* Ex-Large devices (large desktops, 1200px and up) */
@media (min-width: 1400px) {
  .container-fluid {
    width: 1360px;
  }
}



/* Testimonials section of dashboard and profile*/ 

.avis-title{
  margin-bottom: 20px;
}
.testimonials .item {
  margin-bottom: 2rem;
}
.testimonials .item:last-child {
  margin-bottom: 0;
}
.testimonials .item .quote {
  border-left:4px solid #7bb62f;
  padding-left: 1rem;
  margin-bottom: 1rem;
}
.testimonials .item .quote .fa:last-child {
  margin-right: 1rem;
}

.testimonials .item .source .date {
  color: #999999;

}
